export const projects = [
  {
    title: "Clean Collective",
    subtitle: "MERN Stack",
    description:
      "Clean Collective is here to connect everyone with similar goals to help accelerate the movement and increase technological innovations in every industry.",
    image: "./cleanCollective.png",
    link: "https://clean-collective.julianlee.dev/",

  },
  {
    title: "Spacestagram",
    subtitle: "JavaScript, HTML & CSS",
    description:
      "Shopify Front-end challenge to fetch images from the NASA API and format them into an Instagram clone.",
    image: "./spacestagram.png",
    link: "https://jshenlee.github.io/spacestagram/",
  },

    {
      title: "Grocery Order Form",
      subtitle: "JavaScript, HTML & CSS",
      description:
        "Takes user information , order details and delivery details",
      image: "./groceryOrderForm.png",
      link: "https://jshenlee.github.io/Grocery-Order-Form/",
    },
    {
      title: "Bakey Calculator",
      subtitle: "JavaScript, HTML & CSS",
      description:
        "Calculates the total cost as well as the dimensions of the cake and renders an order summary to the screen.",
      image: "./bakeryCalculator.png",
      link: "https://jshenlee.github.io/bakerycalculator/",
    },
  ];

  export const skills = [
    
"HTML, CSS, JavaScript",
"SQL and PL/SQL",
"MongoDB",
"Express",
"React",
"Node",
"Java",
"Material UI",
  ];

  export const testimonials = [
    {
      quote:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
      image: "./andy.png",
      name: "Andy Diep",
      company: "Front-End Web Developer",
    },
    {
      quote:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
      image: "./rhodan.png",
      name: "Rhodan Cervantes",
      company: "Rogers",
    },
    {
      quote:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
      image: "./paignton.png",
      name: "Paignton Wild",
      company: "SAIT Capstone Teammate",
    },
  ];